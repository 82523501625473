<template>
    <div class='app-container'>
        <el-page-header @back="goBack" class="header">
            <template slot="content">
                规则定制：{{firstName}}-{{secondName}}
            </template>
        </el-page-header>

        <el-divider style="margin:0px"></el-divider>

        <!-- <el-card :body-style="{padding: '0px'}" shadow="hover" class="box">
            <div title="添加送审规则" @click="insertRuleClick"
                style="text-align:center;font-size:100px;line-height:230px;color:rgb(187, 187, 187);">
                <i class="el-icon-plus"></i>
            </div>
        </el-card> -->

        <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;">
            <template #buttons>
                <el-button type="success" size="mini" icon="el-icon-plus" @click="insertRuleClick">添加</el-button>
            </template>
        </vxe-toolbar>
        <!-- :loading="loading" -->
        <vxe-table class="mytable-scrollbar" round align="left" ref="sendingRuleList" highlight-current-row
            highlight-hover-row :keyboard-config="{isArrow: true}" :data="sendingRuleList">
            <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
            <vxe-column field="degreeName" title="学位类型" width="350" :show-overflow="'tooltip'"></vxe-column>
            <vxe-column field="reviewTemplateName" title="评阅书" width="350" :show-overflow="'tooltip'"></vxe-column>
            <vxe-column title="操作">
                <template #default="{ row }">
                    <el-button type="text" size="small" icon="el-icon-view" @click='lookClick(row.sendingRuleDetailId)'>
                        预览</el-button>
                    <el-button type="text" size="small" icon="el-icon-edit" @click='editClick(row.sendingRuleDetailId)'>
                        修改</el-button>
                    <el-popconfirm style="margin-left:10px" confirm-button-text='是' cancel-button-text='否'
                        icon="el-icon-info" icon-color="red" title="是否删除？" @confirm='removeRuleHandle(row)'>
                        <el-button type="text" size="small" icon="el-icon-delete" slot="reference">删除
                        </el-button>
                    </el-popconfirm>
                </template>
            </vxe-column>
        </vxe-table>

        <!-- <el-card v-for="item in sendingRuleList" shadow="hover" :body-style="{padding: '0px'}" class="box">
                <div style="text-align:center">
                    <img src="@/assets/img/paper.png" class="image">
                </div>
                <div class="text">
                    学位类型：{{item.degreeName}}
                </div>
                <div class="text">
                    评阅书：{{item.reviewTemplateName}}
                </div>
                <div class="tool">
                    <el-button type="success" size="mini" icon="el-icon-view" circle
                        @click='lookClick(item.sendingRuleDetailId)'></el-button>
                    <el-button type="primary" size="mini" icon="el-icon-edit" circle @click='editClick'></el-button>
                    <el-popconfirm style="margin-left:10px" confirm-button-text='是' cancel-button-text='否'
                        icon="el-icon-info" icon-color="red" title="是否删除？">
                        <el-button type="danger" slot="reference" size="mini" icon="el-icon-delete" circle></el-button>
                    </el-popconfirm>
                </div>
            </el-card> -->

        <vxe-modal v-model="insertModel" width="1000" height="100%" :show-footer='true' show-zoom resize
            :transfer='true'>
            <template #title>
                <span>{{isInsert?'添加送审规则':'修改送审规则'}}</span>
            </template>
            <template #default>
                <el-steps class="step" :active="step" finish-status='success' align-center>
                    <el-step v-for='item in stepText' :title="item"></el-step>
                </el-steps>
                <el-divider></el-divider>
                <div class="stepContent">
                    <div v-show="step==0">
                        <vxe-form :data="formData" title-align="right" title-width="100">
                            <vxe-form-item field="degreeIds" title="学位类型:" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-select v-model="data.degreeIds" placeholder="请选择" size="medium">
                                        <vxe-option v-for="item in degreeList" :key="item.degreeId"
                                            :value="item.degreeId" :label="item.degreeName">
                                        </vxe-option>
                                    </vxe-select>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="reviewTemplateId" title="评阅书:" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-select v-model="data.reviewTemplateIds" placeholder="请选择" size="medium">
                                        <vxe-option v-for="item in templateList" :key="item.reviewTemplateId"
                                            :value="item.reviewTemplateId" :label="item.templateName">
                                        </vxe-option>
                                    </vxe-select>
                                </template>
                            </vxe-form-item>
                        </vxe-form>
                    </div>
                    <div v-show="step == 1">
                        <el-form class="editName">
                            <el-form-item label="选择命名方式：" label-width="20%">
                                <el-checkbox-group v-model="formData.customNames">
                                    <el-checkbox v-for="(value,key) in namingAttrPos" :key="key" :label="key"
                                        @change="selectChange($event,formData,key)">{{key}}</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="选择序号类型：" label-width="20%">
                                <el-radio-group v-model="formData.selectType">
                                    <el-radio v-for="item in sequenceDescVos" :key="item.seqType" :label="item.seqType">
                                        {{item.description}}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="已选方式：" label-width="20%">
                                <div ref='drap'>
                                    <el-tag :key="tag" :data-id='tag' v-for="tag in formData.dynamicTags" closable
                                        :disable-transitions="false" @close="handleClose(tag,formData)">
                                        {{tag}}
                                    </el-tag>
                                </div>
                                <el-input class="input-new-tag" v-if="formData.inputVisible"
                                    v-model="formData.inputValue" ref="saveTagInput" size="small"
                                    @keyup.enter.native="handleInputConfirm(formData)"
                                    @blur="handleInputConfirm(formData)">
                                </el-input>
                                <el-button v-else class="button-new-tag" size="small" @click="showInput(formData)">+
                                    自定义</el-button>
                            </el-form-item>
                        </el-form>
                    </div>

<!--                    <div v-show="step==2">-->

<!--                        <vxe-form :data="formData" title-align="right" title-width="150">-->
<!--                            <vxe-form-item  v-for="item in formData.anonymousRuleData"  :title="item.reviewPlaceholderDescription+':'"  :span="11" :item-render="{}">-->
<!--                                <template #default="{ data }">-->
<!--                                    <vxe-input :key="item.reviewPlaceholderId" v-model="item.anonymousInfoMap" placeholder="替换规则" clearable></vxe-input>-->
<!--                                </template>-->
<!--                            </vxe-form-item>-->
<!--                        </vxe-form>-->
<!--                    </div>-->
                    <div v-show="step==2">

                        <vxe-form :data="formData.anonymousRuleData" title-align="right" title-width="150">
                            <vxe-form-item   v-for="(item,index) in formData.anonymousRuleData['arr']"  :title="item.reviewPlaceholderDescription+':'"  :span="11" :item-render="{}">
                                <template >
<!--                                    :key="item.reviewPlaceholderId"-->
                                    <vxe-input :key="index" v-model="item.anonymousInfoMap" placeholder="替换规则" clearable></vxe-input>
                                </template>
                            </vxe-form-item>
                        </vxe-form>
                    </div>







                    <div v-show="step == 3">
                        <el-form class="editName">
                            <!-- <el-form-item label="论文信息：" label-width="20%">
                                <el-checkbox-group v-model="formData.paperInfos">
                                    <el-checkbox v-for="item in paperInfos" :label="item"></el-checkbox>
                                </el-checkbox-group>
                            </el-form-item> -->
                            <el-form-item label="学生信息：" label-width="20%">
                                <el-checkbox-group v-model="formData.studentInfos">
                                    <el-checkbox v-for="item in studentInfos" :label="item.statisticsFieldsId">
                                        {{item.statisticsName}}</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="论文信息：" label-width="20%">
                                <el-checkbox-group v-model="formData.paperInfos">
                                    <el-checkbox v-for="item in paperInfos" :label="item.statisticsFieldsId">
                                        {{item.statisticsName}}</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="专家信息：" label-width="20%">
                                <el-checkbox-group v-model="formData.expertInfos">
                                    <el-checkbox v-for="item in expertInfos" :label="item.statisticsFieldsId">
                                        {{item.statisticsName}}</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="评阅书信息：" label-width="20%">
                                <el-checkbox-group v-model="formData.reviewInfos">
                                    <el-checkbox v-for="item in reviewInfos" :label="item.statisticsFieldsId">
                                        {{item.statisticsName}}</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="已选方式：" label-width="20%">
                                <div ref='drap1'>
                                    <el-tag :key="tag" :data-id='tag' v-for="tag in infoArr"
                                        :disable-transitions="false">
                                        {{infoMapper[tag].statisticsName}}
                                    </el-tag>
                                </div>
                                <!-- <el-input class="input-new-tag" v-if="formData.inputVisible"
                                    v-model="formData.inputValue" ref="saveTagInput" size="small"
                                    @keyup.enter.native="handleInputConfirm(formData)"
                                    @blur="handleInputConfirm(formData)">
                                </el-input> -->
                                <!-- <el-button v-else class="button-new-tag" size="small" @click="showInput(formData)">+
                                    自定义</el-button> -->
                            </el-form-item>
                        </el-form>
                    </div>
                    <div v-show="step == 4">
                        <vxe-form :data="formData.infoChange" title-align="right" title-width="100">
                            <vxe-form-item field="universityLevel" title="院校级别" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-select v-model="data.universityLevel" placeholder="请选择" size="medium" multiple
                                        clearable>
                                        <vxe-option v-for="item in options.universityLevel" :key="item" :value="item"
                                            :label="item">
                                        </vxe-option>
                                    </vxe-select>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="universityRanking" title="院校排名" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-input v-model="data.universityRanking[0]" placeholder="开始" type="number"
                                        className="number"></vxe-input> -
                                    <vxe-input v-model="data.universityRanking[1]" placeholder="结束" type="number"
                                        className="number"></vxe-input>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="supervisorType" title="导师类型" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-select v-model="data.supervisorType" placeholder="请选择" size="medium" multiple
                                        clearable>
                                        <vxe-option v-for="item in options.supervisorType" :key="item.supervisorTypeId"
                                            :value="String(item.supervisorTypeId)" :label="item.type">
                                        </vxe-option>
                                    </vxe-select>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="expertTitle" title="专家职称" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-select v-model="data.expertTitle" placeholder="请选择" size="medium" multiple
                                        clearable>
                                        <vxe-option v-for="item in options.expertTitle" :key="item.expertTitleId"
                                            :value="String(item.expertTitleId)" :label="item.title">
                                        </vxe-option>
                                    </vxe-select>
                                </template>
                            </vxe-form-item>

                            <vxe-form-item field="sendingNum" title="送审意见数" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-input v-model="data.sendingNum" placeholder="整数类型" type="integer">
                                    </vxe-input>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="localSendingNum" title="本校意见数" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-input v-model="data.localSendingNum" placeholder="整数类型" type="integer">
                                    </vxe-input>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="expertReviewNum" title="专家最大接审数" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-input v-model="data.expertReviewNum" placeholder="整数类型" type="integer">
                                    </vxe-input>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="debarb" title="是否回避" :span="24" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-radio-group v-model="data.debarb">
                                        <vxe-radio :label="1" content="是"></vxe-radio>
                                        <vxe-radio :label="0" content="否"></vxe-radio>
                                    </vxe-radio-group>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="debarbUniversity" title="回避院校" :span="18"
                                v-if="formData.infoChange.debarb == 1" key="debarbUniversity" :item-render="{}">
                                <template #default="{ data }">
                                    <el-input type="textarea" :autosize="{ minRows: 1,maxRows:4}"
                                        key="debarbUniversity_key" placeholder="院校请用中文顿号‘、’分隔"
                                        v-model="data.debarbUniversity">
                                    </el-input>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item :span="6" key="debarbUniversity0" v-if="formData.infoChange.debarb == 1"
                                :item-render="{}">
                                <template #default="{ data }">
                                    <el-upload class="upload-demo" action='#' :auto-upload='false'
                                        key="debarbUniversity0_key" :show-file-list='false' accept=".xls,.xlsx"
                                        :on-change='uploadDebarb' :limit="1" style="float:left">
                                        <el-button size="mini" type="primary" style="margin-right:10px">上传excel
                                        </el-button>
                                    </el-upload>
                                    <el-link type="info" href='/static/指定回避院校模板.xlsx' class="mylink">院校模板下载
                                    </el-link>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="debarbRegion" title="回避地区" :span="18"
                                v-if="formData.infoChange.debarb == 1" key="debarbRegion" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-select v-model="data.debarbRegion" placeholder="请选择地区" filterable multiple clearable transfer>
                                        <vxe-optgroup v-for="province in locations" :label="province.location" >
                                            <vxe-option v-if="province.markets.length > 1" :value="province.location" :label="province.location"></vxe-option>
                                            <vxe-option :value="market" :label="market" v-for="market in province.markets"></vxe-option>
                                        </vxe-optgroup>
                                    </vxe-select>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="appoint" title="是否指定" :span="24" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-radio-group v-model="data.appoint">
                                        <vxe-radio :label="1" content="是"></vxe-radio>
                                        <vxe-radio :label="0" content="否"></vxe-radio>
                                    </vxe-radio-group>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="appointUniversity" title="指定院校" key="appointUniversity" :span="18"
                                v-if="formData.infoChange.appoint == 1" :item-render="{}">
                                <template #default="{ data }">
                                    <el-input type="textarea" :autosize="{ minRows: 1,maxRows:4}"
                                        placeholder="院校请用中文顿号‘、’分隔" v-model="data.appointUniversity">
                                    </el-input>
                                </template>
                            </vxe-form-item>

                            <vxe-form-item field="appointUniversity" :span="6" v-if="formData.infoChange.appoint == 1"
                                key="appointUniversity0" :item-render="{}">
                                <template #default="{ data }">
                                    <el-upload class="upload-demo" action='#' :auto-upload='false'
                                        :show-file-list='false' accept=".xls,.xlsx" :on-change='uploadAppoint'
                                        :limit="1" style="float:left">
                                        <el-button size="mini" type="primary" style="margin-right:10px">上传excel
                                        </el-button>
                                    </el-upload>
                                    <el-link type="info" href='/static/指定回避院校模板.xlsx' class="mylink">院校模板下载
                                    </el-link>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="appointRegion" key="appointRegion" title="指定地区" :span="18"
                                v-if="formData.infoChange.appoint == 1" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-select v-model="data.appointRegion" placeholder="请选择地区" filterable multiple clearable transfer>
                                        <vxe-optgroup v-for="province in locations" :label="province.location">
                                            <vxe-option v-if="province.markets.length > 1" :value="province.location" :label="province.location"></vxe-option>
                                            <vxe-option :value="market" :label="market" v-for="market in province.markets"></vxe-option>
                                        </vxe-optgroup>
                                    </vxe-select>
                                </template>
                            </vxe-form-item>
                        </vxe-form>
                    </div>
                    <div v-show="step == 5">
                        <!-- <vxe-form title-align="right" title-width="100">
                            <vxe-form-item field="universityLevel" title="学科匹配方式" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-select placeholder="请选择" size="medium" multiple
                                        clearable>
                                        <vxe-option  value="指定学科" label="指定学科"></vxe-option>
                                        <vxe-option  value="关联学科" label="关联学科"></vxe-option>
                                    </vxe-select>
                                </template>
                            </vxe-form-item>
                        </vxe-form> -->
                        <vxe-toolbar>
                            <template #buttons>
                                <el-button type="success" size="mini" icon="el-icon-plus"
                                    @click="insertSubjectHandle()">添加
                                </el-button>
                                <el-button type="info" size="mini" icon="el-icon-minus"

                                           @click="deleteSelectCheckBoxRow">删除
                                </el-button>
                            </template>
                        </vxe-toolbar>
                        <vxe-table :data="subjectMatch" ref="subjectMatch" round align="center" keep-source
                            :edit-config="{trigger: 'dblclick', mode: 'cell'}"
                            @checkbox-change="selectChangeEvent"
                        >
                            <vxe-column type="checkbox" width="10%"></vxe-column>
                            <vxe-column field="subjectName" title="学科(双击修改)" width="18%" :show-overflow="'tooltip'"
                                :edit-render="{placeholder: '双击选择...'}">
                                <template #default="{row}">
                                    {{row.subjectName}}
                                </template>
                                <template #edit="{ row }">
                                    <vxe-input v-model="row.subjectName" placeholder="请输入"></vxe-input>
                                </template>
                            </vxe-column>
                            <vxe-column field="matchSubject" title="映射学硕一级学科" width="18%" :show-overflow="'tooltip'"
                                :edit-render="{placeholder: '双击选择...'}">
                                <template #default="{row}">
                                    {{row.matchSubject}}
                                </template>
                                <template #edit="{ row }">
                                    <vxe-input v-model="row.matchSubject" placeholder="请输入"></vxe-input>
                                </template>
                            </vxe-column>
                            <vxe-column field="matchSecondMajor" title="映射二级学科" width="18%" :show-overflow="'tooltip'"
                                :edit-render="{placeholder: '双击选择...'}">
                                <template #default="{row}">
                                    {{row.matchSecondMajor}}
                                </template>
                                <template #edit="{ row }">
                                    <vxe-input v-model="row.matchSecondMajor" placeholder="请输入"></vxe-input>
                                </template>
                            </vxe-column>
                            <vxe-column field="ranks" title="等级(双击修改)" width="15%" :show-overflow="'tooltip'"
                                :edit-render="{placeholder: '双击选择...'}">
                                <template #default="{row}">
                                    {{row.ranks}}
                                </template>
                                <template #edit="{ row }">
                                    <vxe-select v-model="row.ranks" placeholder="请选择" clearable transfer>
                                        <vxe-option v-for="item in levels" :key="item" :value="item" :label="item">
                                        </vxe-option>
                                    </vxe-select> 
                                </template>
                            </vxe-column>
                            <vxe-column field="matchRank" title="匹配等级(双击修改)" width="20%" :show-overflow="'tooltip'"
                                :edit-render="{placeholder: '双击选择...'}">
                                <template #default="{row}">
                                    {{row.matchRank.join(',')}}
                                </template>
                                <template #edit="{ row }">
                                    <vxe-select v-model="row.matchRank" placeholder="请选择" clearable multiple transfer>
                                        <vxe-option v-for="item in levels" :key="item" :value="item" :label="item">
                                        </vxe-option>
                                    </vxe-select>
                                </template>
                            </vxe-column>
                        </vxe-table>
                    </div>
                    <div v-show="step == 6">
                        <vxe-form :data="formData.cost" title-align="right" title-width="100">
                            <vxe-form-item field="firstPayRole" title="首次支付角色" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-select v-model="data.firstPayRole" placeholder="请选择" size="medium">
                                        <vxe-option v-for="item in payRole" :key='item.payRoleId'
                                            :value="item.payRoleId" :label="item.payRole">
                                        </vxe-option>
                                    </vxe-select>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="firstPayPrice" title="首次收费单价" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-input v-model="data.firstPayPrice" placeholder="整数类型" type="integer">
                                    </vxe-input>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="rePayRole" title="复审支付角色" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-select v-model="data.rePayRole" placeholder="请选择" size="medium">
                                        <vxe-option v-for="item in payRole" :key='item.payRoleId'
                                            :value="item.payRoleId" :label="item.payRole">
                                        </vxe-option>
                                    </vxe-select>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="rePayPrice" title="复审收费单价" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-input v-model="data.rePayPrice" placeholder="整数类型" type="integer">
                                    </vxe-input>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="expertPay" title="专家费" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-input v-model="data.expertPay" placeholder="整数类型" type="integer">
                                    </vxe-input>
                                </template>
                            </vxe-form-item>
                        </vxe-form>
                    </div>
                    <div v-show="step == 7">
                        <vxe-form :data="formData.others" title-align="right" title-width="100">
                            <vxe-form-item field="duplicate" title="开通查重" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-radio-group v-model="data.duplicate">
                                        <vxe-radio label="0" content="不开通"></vxe-radio>
                                        <vxe-radio label="1" content="开通"></vxe-radio>
                                    </vxe-radio-group>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="sendingModeId" title="送审模式" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-select v-model="data.sendingModeId" placeholder="请选择" size="medium">
                                        <vxe-option v-for="item in sendingMode" :key='item.sendingModeId'
                                            :value="item.sendingModeId" :label="item.sendingMode">
                                        </vxe-option>
                                    </vxe-select>
                                </template>
                            </vxe-form-item>
                            <vxe-form-item field="reviewDay" title="时间（天）" :span="12" :item-render="{}">
                                <template #default="{ data }">
                                    <vxe-input v-model="data.reviewDay" placeholder="整数类型" type="integer">
                                    </vxe-input>
                                </template>
                            </vxe-form-item>
                        </vxe-form>
                    </div>
                </div>
            </template>
            <template #footer>
                <el-row :gutter="10">
                    <el-col :span="12" style="text-align: left">
                        <el-button @click='prevStep' size="medium" v-show='step != 0' :disabled='nextLoading'>上一步
                        </el-button>
                        &nbsp;
                    </el-col>
                    <el-col :span="12">
                        <el-button @click='nextStep_v2' size="medium" v-show='step != 7' :disabled='nextLoading'>下一步
                        </el-button>
                        <el-button @click='nextStep_v2' type="primary" size="medium" v-show='step == 7'>提交</el-button>
                    </el-col>
                </el-row>
            </template>
        </vxe-modal>
        <!-- {
    "degreeName": "专业硕士",
    "templateName": "1的专业硕士",
    "namingAttrDesc": [
        "学生姓名",
        "论文名称",
        "A,B,C",
        null
    ],
    "statisticalDesc": null,
    "filterCondition": {
        "id": 1552650177275437000,
        "isDeleted": false,
        "universityLevel": "985",
        "supervisorType": null,
        "expertTitle": "1",
        "debarb": 0,
        "appoint": 0,
        "debarbUniversity": null,
        "appointUniversity": null,
        "sendingNum": 2,
        "localSendingNum": 0,
        "expertReviewNum": 2,
        "pattern": null
    },
    "subjectMatchVos": [
        {
            "subjectRankId": 1,
            "subjectName": "计算机科学与技术",
            "ranks": "A+",
            "matchRank": "A+,A,A-"
        },
        {
            "subjectRankId": 2,
            "subjectName": "月抛",
            "ranks": "A",
            "matchRank": "S+,A+"
        }
    ],
    "payConfigVo": {
        "sendingRuleDetailId": null,
        "firstPayRole": "1",
        "firstPayPrice": "200",
        "rePayRole": "2",
        "rePayPrice": "2",
        "expertPay": "200"
    },
    "otherPreview": {
        "duplicate": "万方",
        "sendingMode": null,
        "reviewDay": "20"
    }
} -->
        <vxe-modal v-model="lookModel" width="1000" height="100%" show-zoom resize :transfer='true'>
            <template #title>
                <span>查看送审规则</span>
            </template>
            <el-descriptions :column="2" :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}" border>
                <el-descriptions-item label="学位类型">{{lookData.degreeName}}</el-descriptions-item>
                <el-descriptions-item label="评阅书模板">{{lookData.templateName}}</el-descriptions-item>
                <el-descriptions-item label="评阅书命名规则" :span="2">
                    <el-tag v-for="item in lookData.namingAttrDesc" size="small" v-show="item">{{item}}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="统计表字段" :span="2" labelStyle>
                    <el-tag v-for="item in lookData.statisticalDesc" size="small">{{item}}</el-tag>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="desc" title="遴选条件" :column="2" :labelStyle="{width:'15%'}"
                :contentStyle="{width:'35%'}" border>
                <el-descriptions-item label="院校级别">
                    <el-tag v-for="it in lookData.filterCondition.universityLevel.split('、')" size="mini">
                        {{it}}
                    </el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="院校排名">
                    {{lookData.filterCondition.universityRanking?lookData.filterCondition.universityRanking.replace('、',' - '):''}}
                </el-descriptions-item>

                <el-descriptions-item label="导师类型">
                    <el-tag v-for="it in lookData.filterCondition.supervisorType.split('、')" size="mini">
                        {{$store.state.mapper.typeMap[it]}}
                    </el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="专家职称">
                    <el-tag v-for="it in lookData.filterCondition.expertTitle.split('、')" size="mini">
                        {{$store.state.mapper.titleMap[it]}}
                    </el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="送审意见数">{{lookData.filterCondition.sendingNum}} 份</el-descriptions-item>
                <el-descriptions-item label="专家最大接审数">{{lookData.filterCondition.expertReviewNum}} 份
                </el-descriptions-item>
                <el-descriptions-item label="是否回避" :span="2">
                    {{lookData.filterCondition.debarb?'是':'否'}}
                </el-descriptions-item>
                <el-descriptions-item label="回避院校" :span="2" v-if='lookData.filterCondition.debarb'>
                    {{lookData.filterCondition.debarbUniversity}}</el-descriptions-item>
                <el-descriptions-item label="回避地区" :span="2" v-if='lookData.filterCondition.debarb'>
                    {{lookData.filterCondition.debarbRegion}}</el-descriptions-item>
                <el-descriptions-item label="是否指定" :span="2">
                    {{lookData.filterCondition.appoint?'是':'否'}}
                </el-descriptions-item>
                <el-descriptions-item label="指定院校" :span="2" v-if='lookData.filterCondition.appoint'>
                    {{lookData.filterCondition.appointUniversity}}</el-descriptions-item>
                <el-descriptions-item label="指定地区" :span="2" v-if='lookData.filterCondition.appoint'>
                    {{lookData.filterCondition.appointRegion}}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="desc" title="学科匹配" :column="2" :labelStyle="{width:'15%'}"
                :contentStyle="{width:'35%'}" border>
                <el-descriptions-item v-for="item in lookData.subjectMatchVos" :label="item.subjectName">
                    <el-tag v-for="it in item.matchRank.split(',')" size="mini">{{it}}</el-tag>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="desc" title="费用信息" :column="2" :labelStyle="{width:'15%'}"
                :contentStyle="{width:'35%'}" border>
                <el-descriptions-item label="首次支付角色">{{lookData.payConfigVo.firstPayRole}}</el-descriptions-item>
                <el-descriptions-item label="首次收费单价">{{lookData.payConfigVo.firstPayPrice}} 元</el-descriptions-item>
                <el-descriptions-item label="复审支付角色">{{lookData.payConfigVo.rePayRole}}</el-descriptions-item>
                <el-descriptions-item label="复审收费单价">{{lookData.payConfigVo.rePayPrice}} 元</el-descriptions-item>
                <el-descriptions-item label="专家费">{{lookData.payConfigVo.expertPay}} 元</el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="desc" title="其他配置" :column="2" :labelStyle="{width:'15%'}"
                :contentStyle="{width:'35%'}" border>
                <el-descriptions-item label="查重">{{lookData.otherPreview.duplicate}}</el-descriptions-item>
                <el-descriptions-item label="送审模式">{{lookData.otherPreview.sendingMode}}</el-descriptions-item>
                <el-descriptions-item label="时间">{{lookData.otherPreview.reviewDay}} 天</el-descriptions-item>
            </el-descriptions>
        </vxe-modal>
    </div>
</template>

<script>
    import qs from 'qs'
    import Sortable from 'sortablejs'
    import XLSX from 'xlsx'
    export default {
        data() {
            return {
                insertModel: false,
                isInsert: true,
                lookModel: false,
                sendingRuleList: [],
                step: 0,
                stepText: ['选择评阅书模板', '评阅书命名规则','匿名规则', '配置统计表', '遴选条件', '学科匹配', '费用配置', '其他设置'],
                formData: {
                    sendingDetailId: 0,
                    // 1
                    degreeIds: "",
                    reviewTemplateIds: "",
                    //2
                    dynamicTags: [],
                    customNames: [],
                    selectType: '1',
                    inputValue: '',
                    inputVisible: false,
                    // 3
                    studentInfos: [],
                    paperInfos: [],
                    expertInfos: [],
                    reviewInfos: [],
                    //4
                    infoChange: {
                        "universityLevel": [],
                        "supervisorType": [],
                        "expertTitle": [],
                        "debarb": "0",
                        "appoint": "0",
                        "sendingNum": "2",
                        "localSendingNum": "0",
                        "expertReviewNum": "2",
                        "debarbUniversity": "",
                        "appointUniversity": "",
                        "debarbProvince": "",
                        "appointProvince": "",
                        "appointRegion": '',
                        "debarbRegion": '',
                        "universityRanking": ['', ''],
                    },
                    //6
                    cost: {
                        "firstPayRole": "",
                        "firstPayPrice": "",
                        "rePayRole": "",
                        "rePayPrice": "",
                        "expertPay": ""
                    },
                    // 7
                    others: {
                        duplicate: false,
                        sendingModeId: '',
                        reviewDay: ''
                    },
                    anonymousRuleData:{
                        "arr" : [],
                    },
                },
                lookData: {
                    "degreeName": "",
                    "templateName": "",
                    "namingAttrDesc": [],
                    "statisticalDesc": null,
                    "filterCondition": {
                        "id": 1552650177275437000,
                        "isDeleted": false,
                        "universityLevel": "",
                        "supervisorType": "",
                        "expertTitle": "",
                        "debarb": 0,
                        "appoint": 0,
                        "debarbUniversity": null,
                        "appointUniversity": null,
                        "sendingNum": 2,
                        "localSendingNum": 0,
                        "expertReviewNum": 2,
                        "pattern": null,
                    },
                    "subjectMatchVos": [{
                            "subjectRankId": 1,
                            "subjectName": "计算机科学与技术",
                            "ranks": "A+",
                            "matchRank": "A+,A,A-"
                        },
                        {
                            "subjectRankId": 2,
                            "subjectName": "月抛",
                            "ranks": "A",
                            "matchRank": "S+,A+"
                        }
                    ],
                    "payConfigVo": {
                        "sendingRuleDetailId": null,
                        "firstPayRole": "1",
                        "firstPayPrice": "200",
                        "rePayRole": "2",
                        "rePayPrice": "2",
                        "expertPay": "200"
                    },
                    "otherPreview": {
                        "duplicate": "万方",
                        "sendingMode": null,
                        "reviewDay": "20"
                    }
                },
                // 1
                dynamicTags: [],
                customNames: [],
                inputValue: '',
                inputVisible: false,
                //5
                subjectMatch: [],
                levels: ['A+', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-','其他'],
                studentInfos: [],
                paperInfos: [],
                expertInfos: [],
                reviewInfos: [],
                infoMapper: {},
                infoArr: [],
                paperExpertInfos: [],
                institutionId: '',
                // 第一步数据
                degreeList: [],
                templateList: [],
                degreeReview: {
                    "degreeId": "34",
                    "reviewTemplateId": "14"
                },
                // 第二步数据
                namingAttrPos: {},
                sequenceDescVos: [],
                // 第4步数据
                options: {},
                // 第6步数据
                payRole: [],
                sendingMode: [],
                sendingRuleDetailId: '',
                // 下一步加载中
                nextLoading: false,
                locations:[],
                fakeresdata:{
                    "code": 200,
                    "msg": null,
                    "count": null,
                    "data": [
                        {
                            "reviewPlaceholderId": 1,
                            "reviewPlaceholderName": "expertName",
                            "reviewPlaceholderDescription": "评审专家姓名",
                            "reviewPlaceholderType": 1,
                            "anonymousInfoMap": "***评审专家姓名***"
                        },
                        {
                            "reviewPlaceholderId": 2,
                            "reviewPlaceholderName": "expertDirection",
                            "reviewPlaceholderDescription": "评审专家研究方向",
                            "reviewPlaceholderType": 1,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 3,
                            "reviewPlaceholderName": "expertTitle",
                            "reviewPlaceholderDescription": "评审专家职称",
                            "reviewPlaceholderType": 1,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 4,
                            "reviewPlaceholderName": "expertInstitution",
                            "reviewPlaceholderDescription": "评审专家单位",
                            "reviewPlaceholderType": 1,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 5,
                            "reviewPlaceholderName": "expertMobile",
                            "reviewPlaceholderDescription": "评审专家联系电话",
                            "reviewPlaceholderType": 1,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 6,
                            "reviewPlaceholderName": "expertEmail",
                            "reviewPlaceholderDescription": "评审专家邮箱",
                            "reviewPlaceholderType": 1,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 7,
                            "reviewPlaceholderName": "reviewDate",
                            "reviewPlaceholderDescription": "评审日期",
                            "reviewPlaceholderType": 1,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 8,
                            "reviewPlaceholderName": "expertType",
                            "reviewPlaceholderDescription": "导师类型",
                            "reviewPlaceholderType": 1,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 9,
                            "reviewPlaceholderName": "expertSignature",
                            "reviewPlaceholderDescription": "专家签名",
                            "reviewPlaceholderType": 1,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 10,
                            "reviewPlaceholderName": "firstGradeSubject",
                            "reviewPlaceholderDescription": "评审专家一级学科",
                            "reviewPlaceholderType": 1,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 11,
                            "reviewPlaceholderName": "secondGradeSubject",
                            "reviewPlaceholderDescription": "评审专家二级学科",
                            "reviewPlaceholderType": 1,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 12,
                            "reviewPlaceholderName": "ExpertIdNum",
                            "reviewPlaceholderDescription": "专家身份证号",
                            "reviewPlaceholderType": 1,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 13,
                            "reviewPlaceholderName": "studentName",
                            "reviewPlaceholderDescription": "学生姓名",
                            "reviewPlaceholderType": 0,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 14,
                            "reviewPlaceholderName": "studentMajor",
                            "reviewPlaceholderDescription": "学生学科",
                            "reviewPlaceholderType": 0,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 15,
                            "reviewPlaceholderName": "studentNum",
                            "reviewPlaceholderDescription": "学生学号",
                            "reviewPlaceholderType": 0,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 16,
                            "reviewPlaceholderName": "studentCollege",
                            "reviewPlaceholderDescription": "学生单位",
                            "reviewPlaceholderType": 0,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 17,
                            "reviewPlaceholderName": "studentDirection",
                            "reviewPlaceholderDescription": "研究方向",
                            "reviewPlaceholderType": 0,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 18,
                            "reviewPlaceholderName": "tutorName",
                            "reviewPlaceholderDescription": "导师姓名",
                            "reviewPlaceholderType": 0,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 19,
                            "reviewPlaceholderName": "paperOriginId",
                            "reviewPlaceholderDescription": "论文编号",
                            "reviewPlaceholderType": 0,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 20,
                            "reviewPlaceholderName": "index",
                            "reviewPlaceholderDescription": "评阅书序号",
                            "reviewPlaceholderType": 0,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 21,
                            "reviewPlaceholderName": "paperTitle",
                            "reviewPlaceholderDescription": "论文标题",
                            "reviewPlaceholderType": 0,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 22,
                            "reviewPlaceholderName": "paperKeywords",
                            "reviewPlaceholderDescription": "论文关键词",
                            "reviewPlaceholderType": 0,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 23,
                            "reviewPlaceholderName": "paperType",
                            "reviewPlaceholderDescription": "论文类型",
                            "reviewPlaceholderType": 0,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 24,
                            "reviewPlaceholderName": "studentFirstMajor",
                            "reviewPlaceholderDescription": "学生一级学科",
                            "reviewPlaceholderType": 0,
                            "anonymousInfoMap": null
                        },
                        {
                            "reviewPlaceholderId": 25,
                            "reviewPlaceholderName": "studentSecondMajor",
                            "reviewPlaceholderDescription": "学生二级学科",
                            "reviewPlaceholderType": 0,
                            "anonymousInfoMap": null
                        }
                    ]
                },

            }
        },
        computed: {
            firstName() {
                return this.$store.state.custom.institution.first;
            },
            secondName() {
                return this.$store.state.custom.institution.second;
            },
            // tableFileds: {
            //     get() {
            //         this.infoArr = [...this.formData.studentInfos, ...this.formData.paperInfos, ...this.formData
            //             .expertInfos, ...this.formData.reviewInfos
            //         ];
            //         return this.infoArr;
            //     },
            //     set(val) {
            //         this.infoArr = val;
            //     }
            // }
        },
        watch: {
            'formData.studentInfos': function (ne, old) {
                this.solveSort(ne, old);
            },
            'formData.paperInfos': function (ne, old) {
                this.solveSort(ne, old);
            },
            'formData.expertInfos': function (ne, old) {
                this.solveSort(ne, old);
            },
            'formData.reviewInfos': function (ne, old) {
                this.solveSort(ne, old);
            }
        },
        created() {
            this.institutionId = this.$route.params.id;
            this.getDegree();
            this.getTemplate();
            this.getFilterOptions();
            this.getSubjects();
            this.getPayRole();
            this.getSendingMode();
            this.getPreviews();
            this.getLocation();
        },
        methods: {
            getLocation(){
                this.$api.schoolCustom.getLocation()
                .then(res=>{
                    if(res.data.code == 200){
                        this.locations = res.data.data;
                    }
                })
            },
            solveSort(ne, old) {
                ne.forEach(item => {
                    if (old.indexOf(item) == -1) {
                        this.infoArr.push(item);
                    }
                })
                old.forEach(item => {
                    let index = ne.indexOf(item);
                    if (index == -1) {
                        index = this.infoArr.indexOf(item);
                        this.infoArr.splice(index, 1);
                    }
                })
            },
            goBack() {
                this.$router.push({
                    name: 'schoolCustom_index'
                })
            },
            // 获取某院校送审规则预览
            getPreviews() {
                let params = {
                    institutionId: this.institutionId
                };
                this.$api.schoolCustom.getPreviews(params).then(res => {
                    if (res.data.code) {
                        this.sendingRuleList = res.data.data;
                    }
                }).catch(err => {
                    this.$message.warning("请求出错");
                })
            },
            insertRuleClick() {
                // 点击插入规则
                // this.resetFormData()
                this.step = 0;
                this.resetFormData()
                this.isInsert = true;
                this.insertModel = true;
            },
            editClick(id) {
                // 点击编辑规则
                this.nextLoading = false;
                this.sendingRuleDetailId = id;
                this.step = 0;
                this.resetFormData()
                this.getInfo1();
                this.insertModel = true;
                this.$nextTick(() => {
                    this.isInsert = false;
                })
            },
            lookClick(id) {
                // 查看送审规则
                let params = {
                    sendingRuleDetailId: id
                };
                this.$api.schoolCustom.getOverview(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.lookData = res.data.data;
                        }
                    }).catch(err => {
                        this.$message.warning("请求出错！")
                    })
                this.lookModel = true;
            },
            // 删除规则
            removeRuleHandle(row) {
                const loading = this.$loading({
                    lock: true,
                    text: '删除中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let params = {
                    sendingRuleDetailId: row.sendingRuleDetailId
                }
                this.$api.schoolCustom.postDeleteSendingRule(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功！");
                            this.getPreviews();
                        } else {
                            this.$message.warning("删除失败！");
                        }
                        loading.close();
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                        loading.close();
                    })
            },
            // 获取某个送审策略的总览
            getDegree() {
                let params = {
                    institutionId: this.institutionId
                };
                this.$api.schoolCustom.getDegree(params)
                    .then(res => {
                        this.degreeList = res.data.data;
                    }).catch(err => {
                        this.$message.warning("获取学位类型错误");
                    })
            },
            getTemplate() {
                let params = {
                    institutionId: this.institutionId
                }
                this.$api.schoolCustom.getTemplate(params)
                    .then(res => {
                        this.templateList = res.data.data;
                    })
            },
            // 保存第一步
            // 获取学位类型和评阅书
            getInfo1() {
                let params = {
                    sendingRuleDetailId: this.sendingRuleDetailId
                }
                this.$api.schoolCustom.getSendingPolicy1(params)
                    .then(res => {
                        this.formData.degreeIds = res.data.data.degree.degreeId;
                        this.formData.reviewTemplateIds = res.data.data.reviewTemplate.reviewTemplateId;
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            postDegreeReview() {
                let params = {
                    institutionId: this.institutionId,
                    "degreeIds": [this.formData.degreeIds],
                    "reviewTemplateIds": [this.formData.reviewTemplateIds]
                };
                this.$api.schoolCustom.postDegreeReview(params).then(res => {
                    if (res.data.code == 200) {
                        this.step++;
                        this.formData.sendingDetailId = res.data.data.sendingRuleDetailIds[0];
                        this.getNamingRule();
                    }
                }).catch(err => {
                    console.log('请求出错！');
                })
            },
            // 获取命名规则字段
            getNamingRule() {
                this.$api.schoolCustom.getNamingRule().then(res => {
                    if (res.data.code == 200) {
                        let arr = {};
                        res.data.data.namingAttrPos.forEach(item => {
                            arr[item.description] = item.namingAttrId;
                        });
                        this.namingAttrPos = arr;
                        this.sequenceDescVos = res.data.data.sequenceDescVos;
                        this.$nextTick(() => {
                            let el = this.$refs.drap;
                            console.log(el);
                            let that = this;
                            let opt = {
                                animation: 1000,
                                //拖动结束
                                onEnd: function (evt) {
                                    console.log(evt);
                                    //获取拖动后的排序
                                    that.formData.dynamicTags = sortable.toArray();
                                    console.log(that.formData.dynamicTags);
                                },
                            };
                            var sortable = Sortable.create(el, opt)
                        });
                    }
                }).catch(err => {
                    this.$message.warning("请求出错");
                })
            },
            // 获取第二步的信息
            getInfo2() {
                let params = {
                    sendingRuleDetailId: this.sendingRuleDetailId
                }
                this.$api.schoolCustom.getSendingPolicy2(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            let arr = [],
                                arr2 = [];
                            res.data.data.forEach(item => {
                                if (item.seqType) {
                                    this.formData.selectType = item.seqType;
                                } else {
                                    arr.push(item.description);
                                }
                            });
                            this.formData.customNames = arr;
                            // '论文名称', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '序号',
                            // console.log(this.formData.customNames)
                            this.formData.dynamicTags = arr;
                            setTimeout(() => {
                                this.nextLoading = false;
                            }, 500);
                        } else {
                            this.$message.warning("获取信息失败");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            // 保存命名规则
            postNamingRule(d) {
                let oldArr = [],
                    newArr = [];
                let seq = [];
                this.formData.dynamicTags.forEach(item => {
                    if (this.namingAttrPos.hasOwnProperty(item)) {
                        oldArr.push(this.namingAttrPos[item]);
                        seq.push(this.namingAttrPos[item]);
                    } else {
                        newArr.push(item);
                        seq.push(item);
                    }
                })
                let params = [{
                    sendingDetailId: this.formData.sendingDetailId,
                    namingAttrIds: oldArr,
                    customAttrs: newArr,
                    seqType: this.formData.selectType,
                    order: seq
                }];
                console.log(params);
                this.$api.schoolCustom.postNamingRule(params).then(res => {
                    if (res.data.code = 200) {
                        this.step = this.step + d;

                    }
                }).catch(err => {
                    this.$message.warning("请求出错");
                })
            },
            // 获取第三步信息
            getStatisticsFields() {
                let params = {
                    sendingRuleDetailId: this.formData.sendingDetailId
                }
                this.$api.schoolCustom.getStatisticsFields(params)
                    .then(res => {
                        this.reviewInfos = [];
                        this.paperExpertInfos = [];
                        // console.log(res.data.data);
                        this.studentInfos = res.data.data.fieldsPos.filter(item => item.type == 0);
                        this.paperInfos = res.data.data.fieldsPos.filter(item => item.type == 2);
                        this.expertInfos = res.data.data.fieldsPos.filter(item => item.type == 3);
                        this.reviewInfos = res.data.data.fieldsPos.filter(item => item.type == 1);
                        res.data.data.fieldsPos.forEach(item => {
                            this.infoMapper[item.statisticsFieldsId] = item;
                        });
                        this.$nextTick(() => {
                            let el = this.$refs.drap1;
                            console.log(el);
                            let that = this;
                            let opt = {
                                animation: 1000,
                                //拖动结束
                                onEnd: function (evt) {
                                    console.log(evt);
                                    //获取拖动后的排序
                                    console.log(sortable.toArray());
                                    that.infoArr = sortable.toArray();
                                    // console.log(that.formData.dynamicTags);
                                },
                            };
                            var sortable = Sortable.create(el, opt)
                        });
                    }).catch(err => {
                        this.$message.warning("配置信息功能出错！");
                    })
            },
            // 保存信息表字段
            postTableFileds(d) {
                // let arr = [];
                // this.formData.expertInfos.forEach(item => {
                //     arr.push(item);
                // })
                // this.formData.reviewInfos.forEach(item => {
                //     arr.push(item);
                // })
                let params = {
                    sendingRuleDetailId: this.formData.sendingDetailId,
                    statisticsFieldsIds: this.infoArr
                }
                this.$api.schoolCustom.postStatisticsFields(params)
                    .then(res => {
                        this.step++;
                    })
                    .catch(err => {
                        this.$message.warning("保存信息表字段错误");
                    })
            },
            // 获取第三步信息
            getInfo3() {
                let params = {
                    sendingRuleDetailId: this.sendingRuleDetailId
                }
                this.$api.schoolCustom.getSendingPolicy3(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            res.data.data.forEach(item => {
                                this.infoArr.push(item.statisticsFieldsId);
                                if (item.type == 0) this.formData.studentInfos.push(item.statisticsFieldsId)
                                if (item.type == 2) this.formData.paperInfos.push(item.statisticsFieldsId)
                                if (item.type == 3) this.formData.expertInfos.push(item.statisticsFieldsId)
                                if (item.type == 1) this.formData.reviewInfos.push(item.statisticsFieldsId)

                            })
                            setTimeout(() => {
                                this.nextLoading = false;
                            }, 500);
                            // res.data.data.forEach(item => {
                            // })
                        }
                    }).catch(err => {
                        this.$message.warning("获取信息字段错误");
                    })
            },
            // 获取专家遴选选项
            getFilterOptions() {
                this.$api.schoolCustom.getFilterOptions().then(res => {
                    if (res.data.code = 200) {
                        this.options = res.data.data;
                    }
                }).catch(err => {
                    this.$message.warning("请求出错");
                })
            },
            // 上传指定院校
            uploadAppoint(file, fileList) {
                const loading = this.$loading({
                    lock: true,
                    text: '上传中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                // let rawFile = this.$refs.templateFile.uploadFiles[0];
                // console.log(rawFile.raw);
                var reader = new FileReader(); //读取文件内容
                reader.readAsArrayBuffer(file.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
                reader.onload = (e) => {
                    var dd = e.target.result;
                    var workbook = XLSX.read(dd, {
                        type: 'buffer'
                    });
                    var sheetname = workbook.SheetNames[0]

                    var tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname]);
                    var schools = [];
                    for (let i in tables) {
                        let row = tables[i];
                        schools.push(row['院校名称']);
                    };
                    console.log(schools);
                    this.formData.infoChange.appointUniversity = schools.join('、');
                    loading.close();
                }
            },
            // 上传回避院校
            uploadDebarb(file, fileList) {
                const loading = this.$loading({
                    lock: true,
                    text: '上传中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                // let rawFile = this.$refs.templateFile.uploadFiles[0];
                // console.log(rawFile.raw);
                var reader = new FileReader(); //读取文件内容
                reader.readAsArrayBuffer(file.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
                reader.onload = (e) => {
                    var dd = e.target.result;
                    var workbook = XLSX.read(dd, {
                        type: 'buffer'
                    });
                    var sheetname = workbook.SheetNames[0]

                    var tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname]);
                    var schools = [];
                    for (let i in tables) {
                        let row = tables[i];
                        schools.push(row['院校名称']);
                    };
                    console.log(schools);
                    this.formData.infoChange.debarbUniversity = schools.join('、');
                    loading.close();
                }
            },
            // 获取第4步信息
            getInfo4() {
                let params = {
                    sendingRuleDetailId: this.sendingRuleDetailId
                }
                this.$api.schoolCustom.getSendingPolicy4(params)
                    .then(res => {

                        if (res.data.code == 200) {
                            let item = res.data.data;
                            this.formData.infoChange = {
                                "universityLevel": item.universityLevel?item.universityLevel.split(
                                    '、'):[],
                                "supervisorType": item.supervisorType?item.supervisorType.split('、'):[],
                                "expertTitle": item.expertTitle?item.expertTitle.split('、'):[],
                                "debarb": item.debarb,
                                "appoint": item.appoint,
                                "sendingNum": item.sendingNum,
                                "localSendingNum": item.localSendingNum,
                                "expertReviewNum": item.expertReviewNum,
                                "debarbUniversity": item.debarbUniversity,
                                "appointUniversity": item.appointUniversity,
                                "appointRegion": item.appointRegion?item.appointRegion.split('、'):[],
                                "debarbRegion": item.debarbRegion?item.debarbRegion.split('、'):[],
                                "universityRanking": item.universityRanking == null ? ['', ''] : item
                                    .universityRanking.split('-'),
                            };
                            setTimeout(() => {
                                this.nextLoading = false;
                            }, 500);
                        } else {
                            this.$message.warning("获取信息失败");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            // 保存遴选条件
            postFilter(d) {
                console.log(this.formData.infoChange)
                let params = [{
                    "sendingRuleDetailId": this.formData.sendingDetailId,
                    "filterCondition": {
                        "universityLevel": this.formData.infoChange.universityLevel.join('、'),
                        "supervisorType": this.formData.infoChange.supervisorType.join('、'),
                        "expertTitle": this.formData.infoChange.expertTitle.join('、'),
                        "appointRegion": this.formData.infoChange.appointRegion?this.formData.infoChange.appointRegion.join('、'):'',
                        "debarbRegion": this.formData.infoChange.appointRegion && this.formData.infoChange.debarbRegion != null?this.formData.infoChange.debarbRegion.join('、'):'',
                        "universityRanking": this.formData.infoChange.appointRegion?this.formData.infoChange.universityRanking.join('-'):'',
                        "debarb": this.formData.infoChange.debarb,
                        "appoint": this.formData.infoChange.appoint,
                        "sendingNum": this.formData.infoChange.sendingNum,
                        "localSendingNum": this.formData.infoChange.localSendingNum,
                        "expertReviewNum": this.formData.infoChange.expertReviewNum,
                        "debarbUniversity": this.formData.infoChange.debarbUniversity,
                        "appointUniversity": this.formData.infoChange.appointUniversity
                    }
                }];
                this.$api.schoolCustom.postFilter(params).then(res => {
                    if (res.data.code == 200) {
                        this.step += d;
                    }
                }).catch(err => {
                    this.$message.warning("请求出错！");
                })
            },
            // 根据学校id获取学科评估
            getSubjects() {
                let params = {
                    institutionId: this.institutionId
                };
                this.$api.schoolCustom.getSubjects(params)
                    .then(res => {
                        let arr = [];
                        res.data.data.forEach(item => {
                            item.matchRank = item.matchRank.split('、');
                            arr.push(item);
                        })
                        this.subjectMatch = arr;
                        console.log(this.subjectMatch);
                    })
            },
            deleteSelectCheckBoxRow(){
                console.log(this.$refs.subjectMatch)
                this.$refs.subjectMatch.removeCheckboxRow();
                const { insertRecords, removeRecords, updateRecords } = this.$refs.subjectMatch.getRecordset();
                let filterSubjects = this.subjectMatch.filter(item=>{
                    return !removeRecords.includes(item);
                });

                this.subjectMatch = filterSubjects;
                console.log(this.subjectMatch)

            },
            insertSubjectHandle(row) {
                this.subjectMatch.unshift({
                    "subjectRankId": '',
                    "ranks": '',
                    "matchRank": [],
                    "subjectName": '',
                    'matchSubject': '',
                    'matchSecondMajor': ''
                })
                let $subjectMatch = this.$refs.subjectMatch;
                $subjectMatch.setEditRow(this.subjectMatch[0]);
            },
            
            selectChangeEvent ({ checked }) {
                // this.$refs.subjectMatch.getCheckboxRecords()

                // console.log(checked ? '勾选事件' : '取消事件', records)
            },
            // 获取第5步信息
            getInfo5() {

            },
            // 保存学科评估以及对应的送审评估等级
            postSubjects(d) {
                let subjectRankEntities = [];
                console.log(this.subjectMatch);
                this.subjectMatch.forEach(item => {
                    subjectRankEntities.push({
                        "subjectRankId": item.subjectRankId,
                        "ranks": item.ranks,
                        "matchRank": item.matchRank.join('、'),
                        "subjectName": item.subjectName,
                        'matchSubject': item.matchSubject,
                        'matchSecondMajor': item.matchSecondMajor
                    })
                });
                let params = {
                    subjectRankEntities: subjectRankEntities,
                    institutionId: this.institutionId,
                };
                this.$api.schoolCustom.postSubjects(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.step += d;
                        }
                    })
            },
            // 获取支付角色
            getPayRole() {
                this.$api.schoolCustom.getPayRole()
                    .then(res => {
                        this.payRole = res.data.data;
                    })
            },
            // 获取第6步信息
            getInfo6() {
                let params = {
                    sendingRuleDetailId: this.sendingRuleDetailId
                }
                this.$api.schoolCustom.getSendingPolicy6(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            let item = res.data.data.pay;
                            this.formData.cost = {
                                firstPayRole: Number(item.payRole),
                                firstPayPrice: item.reReward,
                                rePayRole: item.rePayRole,
                                rePayPrice: item.reReward,
                                expertPay: item.expertReward,
                            }
                            setTimeout(() => {
                                this.nextLoading = false;
                            }, 500);
                        } else {
                            this.$message.warning("获取信息失败");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            // 保存支付模式以及其他内容
            postPay(d) {
                let params = [{
                    sendingRuleDetailId: this.formData.sendingDetailId,
                    firstPayRole: this.formData.cost.firstPayRole,
                    firstPayPrice: this.formData.cost.firstPayPrice,
                    rePayRole: this.formData.cost.rePayRole,
                    rePayPrice: this.formData.cost.rePayPrice,
                    expertPay: this.formData.cost.expertPay,
                }];
                this.$api.schoolCustom.postPay(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.step += d;
                        }
                    })
            },
            // 获取送审模式
            getSendingMode() {
                this.$api.schoolCustom.getSendingMode()
                    .then(res => {
                        this.sendingMode = res.data.data;
                    })
            },
            // 获取第7步信息
            getInfo7() {
                let params = {
                    sendingRuleDetailId: this.sendingRuleDetailId
                }
                this.$api.schoolCustom.getSendingPolicy7(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            let item = res.data.data.data;
                            this.formData.others = {
                                duplicate: item.duplicate,
                                sendingModeId: item.sendingModeId,
                                reviewDay: item.reviewDay
                            }
                            setTimeout(() => {
                                this.nextLoading = false;
                            }, 500);
                        } else {
                            this.$message.warning("获取信息失败");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            // 保存其他
            postOther(d) {
                let params = [{
                    sendingRuleDetailId: this.formData.sendingDetailId,
                    otherDetail: {
                        duplicate: this.formData.others.duplicate,
                        sendingModeId: this.formData.others.sendingModeId,
                        reviewDay: this.formData.others.reviewDay
                    }
                }];
                this.$api.schoolCustom.postOther(params).then(res => {
                    if (res.data.code == 200) {
                        this.step += d;
                        this.insertModel = false;
                        this.getPreviews();
                        this.getTemplate();
                    }
                })
            },
            // 下一步
            nextStep() {
                if (!this.isInsert) this.nextLoading = true;
                console.log('this.nextLoading ' + this.nextLoading);
                switch (this.step) {
                    case 0:
                        this.postDegreeReview();
                        if (!this.isInsert) this.getInfo2();
                        break;
                    case 1:
                        this.postNamingRule(1);
                        this.getStatisticsFields();
                        if (!this.isInsert) this.getInfo3();
                        break;

                    case 2:
                        this.postTableFileds(1);
                        if (!this.isInsert) this.getInfo4();
                        break;
                    case 3:
                        this.postFilter(1);
                        if (!this.isInsert) setTimeout(() => {
                            this.nextLoading = false;
                        }, 500);
                        break;
                    case 4:
                        this.postSubjects(1);
                        console.log(this.isInsert)
                        if (!this.isInsert) this.getInfo6();
                        break;
                    case 5:
                        this.postPay(1);
                        if (!this.isInsert) this.getInfo7();
                        break;
                    case 6:
                        this.postOther(0);
                        break;

                }
                // if (this.step < 6) this.step++;
            },
            nextStep_v2() {
                if (!this.isInsert) this.nextLoading = true;
                console.log('this.nextLoading ' + this.nextLoading);
                switch (this.step) {
                    case 0:
                        this.postDegreeReview();
                        if (!this.isInsert) this.getInfo2();
                        break;
                    case 1:

                        this.postNamingRule(1);
                        this.getInfoAnonymous();
                        // /school/sendingPolicy/2_1

                        // if (!this.isInsert) this.getInfoAnonymous();

                        console.log(1)
                        break;
                    case 2:
                        console.log(this.formData.anonymousRuleData);
                        this.savePlaceholderMap(1);
                        
                        // this.step++;
                        this.getStatisticsFields();
                        if (!this.isInsert) this.getInfo3();
                        break;
                    case 3:
                        this.postTableFileds(1);
                        if (!this.isInsert) this.getInfo4();
                        break;
                    case 4:
                        console.log("遴选条件")
                        this.postFilter(1);
                        if (!this.isInsert) setTimeout(() => {
                            this.nextLoading = false;
                        }, 500);
                        break;
                    case 5:
                        this.postSubjects(1);
                        if (!this.isInsert) this.getInfo6();
                        break;
                    case 6:
                        this.postPay(1);
                        if (!this.isInsert) this.getInfo7();
                        break;
                    case 7:
                        this.postOther(0);
                        this.nextLoading = false;
                        break;

                }
                // if (this.step < 6) this.step++;
            },
            // 上一步
            prevStep() {
                if (this.step > 0) this.step--;
            },
            //2
            selectChange(val, item, key) {
                console.log(val, key);
                if (key == "序号") {
                    this.formData.selectType = val ? '1' : '';
                }
                item.dynamicTags = item.customNames;
            },
            resetFormData() {
                // 重置弹窗内容
                this.formData = {
                    sendingDetailId: 0,
                    // 1
                    degreeIds: "",
                    reviewTemplateIds: "",
                    //2
                    dynamicTags: [],
                    customNames: [],
                    selectType: '',
                    inputValue: '',
                    inputVisible: false,
                    // 3
                    studentInfos: [],
                    paperInfos: [],
                    expertInfos: [],
                    reviewInfos: [],
                    //4
                    infoChange: {
                        "universityLevel": [],
                        "supervisorType": [],
                        "expertTitle": [],
                        "debarb": 0,
                        "appoint": 0,
                        "sendingNum": 0,
                        "localSendingNum": 0,
                        "expertReviewNum": 0,
                        "debarbUniversity": "",
                        "appointUniversity": "",
                        "appointRegion": '',
                        "debarbRegion": '',
                        "universityRanking": [0, 1],
                    },
                    //6
                    cost: {
                        "firstPayRole": "",
                        "firstPayPrice": "",
                        "rePayRole": "",
                        "rePayPrice": "",
                        "expertPay": ""
                    },
                    // 7
                    others: {
                        duplicate: false,
                        sendingModeId: '',
                        reviewDay: ''
                    },
                    anonymousRuleData: {
                        arr:[]
                    }
                }
            },
            handleClose(tag, item) {
                item.dynamicTags.splice(item.dynamicTags.indexOf(tag), 1);
            },
            handleInputConfirm(item) {
                let inputValue = item.inputValue;
                if (inputValue) {
                    item.dynamicTags.push(inputValue);
                }
                item.inputVisible = false;
                item.inputValue = '';
            },
            showInput(item) {
                item.inputVisible = true;
                this.$nextTick(_ => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },
            getInfoAnonymous(){
                // let data = this.fakeresdata.data;
                // this.anonymousRuleData = data;
                // let temp = {};
                // this.formData.anonymousRuleData= data;
                // console.log(this.anonymousRuleData)
                // let params = {
                //     sendingRuleDetailId: this.formData.sendingDetailId
                // };
                let params = new URLSearchParams()
                params.append('sendingRuleDetailId', this.formData.sendingDetailId)
                console.log(params)

                this.$api.schoolCustom.getAnonymousInfo(params).then(res => {
                    console.log(res)
                    if (res.data.code == 200) {
                        this.formData.anonymousRuleData={
                            "arr" : res.data.data
                        }

                        setTimeout(() => {
                            this.nextLoading = false;
                        }, 500);
                    } else {
                        this.$message.warning("获取信息失败");
                    }
                }).catch(err => {
                    console.log(err)
                    this.$message.warning("服务器维护！");
                });

            },
            savePlaceholderMap(d){
                let params = {
                    sendingRuleDetailId: this.formData.sendingDetailId,
                    placeholderInfoMapDTOs:this.formData.anonymousRuleData.arr
                };
                // console.log(params)
                // return;
                this.$api.schoolCustom.savePlaceholderMap(params).then(res => {
                    console.log(res);
                    if (res.data.code = 200) {
                        this.step = this.step + d;

                    }
                }).catch(err => {
                    console.log(err)
                    this.$message.warning("请求出错");
                })
            }

        },
        components: {

        }
    }
</script>

<style lang='css' scoped>
    /* .header {
        padding-bottom: 20px;
        border-bottom: 1px #000 solid;
        box-shadow: 0 0px 4px rgb(0 21 42 / 8%);
    } */

    .box {
        width: 200px;
        height: 230px;
        margin: 0 20px 20px 0px;
        float: left;
    }

    .image {
        width: 130px;
        height: 150px;
    }

    .box .text {
        color: rgb(187, 187, 187);
        font-size: 14px;
        padding-bottom: 5px;
        margin-left: 20px;
    }

    .box .tool {
        /* float: right; */
        text-align: right;
        padding-right: 20px;
    }

    .desc {
        margin-top: 15px;
    }

    /*滚动条的轨道*/
    .box::-webkit-scrollbar-track {
        background-color: #FFFFFF;
    }

    /*滚动条里面的小方块，能向上向下移动*/
    .box::-webkit-scrollbar {
        width: 8px;
    }

    .box::-webkit-scrollbar-thumb {
        background-color: #bfbfbf;
        border-radius: 5px;
        border: 1px solid #F1F1F1;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    }

    .box::-webkit-scrollbar-thumb:hover {
        background-color: #A8A8A8;
    }

    .box::-webkit-scrollbar-thumb:active {
        background-color: #787878;
    }

    .number {
        width: 45% !important;
    }
</style>

<style>
    .tool .el-button--mini.is-circle {
        padding: 4px;
    }

    .el-divider--horizontal {
        margin: 20px 0px;
    }

    .step .el-step__title {
        font-size: 14px;
    }

    .el-tag {
        margin-right: 10px;
    }

    .button-new-tag {
        /* margin-left: 10px; */
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 90px;
        /* margin-left: 10px; */
        vertical-align: bottom;
    }

    .box.el-card {
        overflow: auto;
    }
</style>